.logo-container {
  text-align: center;
  margin: 0 auto;
}

.hero-banner {
  height: 371px;
  width: 100%;
  max-width: 1980px;
  margin: 0 auto;
  background-position: 0 80%;
}

.config-float-box {
  position: fixed;
  bottom: 11px;
  right: 21px;
  display: flex;
  flex-direction: column;
  align-items: self-end;
}

.config-view-box {
  color: gray;
  font-size: 10px;
}

.config-view-box>b {
  min-width: 60px;
  display: inline-block;
}

.win-close-btn {
  text-align: right;
  text-decoration: none;
  font-weight: bold;
  right: 21px;
}

.edit-config-btn {
  color: black;
  text-decoration: none;
  font-weight: bold;
  width: 20px;
}

.edit-config-btn svg {
  width: 20px;
  transition: 0.9s;
  transform: rotate(0deg);
  fill: black;
}

.edit-config-btn:hover svg {
  transition: 0.9s;
  transform: rotate(180deg);
  color: red;
}

body {
  margin: 0;
  font-family: DaimlerCS, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-size: cover;
}

.hpp-background {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

}

.header-top {
  height: 45px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

select {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' id='Layer_1' x='0' y='0' style='enable-background:new 0 0 32 32' version='1.1' viewBox='0 0 32 32'%3E%3Cstyle%3E.st0%7Bfill:none%3Bstroke:%2312284c%3Bstroke-linecap:round%3Bstroke-linejoin:round%3Bstroke-miterlimit:10%7D%3C/style%3E%3Cswitch%3E%3Cg%3E%3Cpath d='M16 23.9 4 8.1h24L16 23.9z' style='fill:%23dc0000'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E") no-repeat right white;

  background-position: right 10px center;
  background-size: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.content-body {

  margin: 0 auto;
}

#demoPaymentForm {
  margin: 0 auto;
}

.logo {
  margin: 20px 20px 20px 0;
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert.alert-danger {
  color: #721c24;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
}

.success-box {
  padding: 10px 20px;
  align-content: center;
  display: grid;
}

.virtual-account-details .pd-row {
  margin-bottom: 6px;
  padding-bottom: 6px;
  border-bottom: solid 1px #f4f4f4;
}

.virtual-account-details .pd-subtext {
  font-size: 14px;
  text-align: left;
  font-weight: 800;
  font-style: italic;
  background-color: #f4f4f4;
  padding: 4px;
  border-radius: 4px;
  margin-top: 10px;
}

.virtual-account-details .note {
  margin-top: 40px;
  padding-top: 10px;
  border-top: solid 1px #333;
  color: #434343;
  text-align: left;
  padding-bottom: 5px;
}

.va-btn {
  margin: 10px !important;
  margin-top: 20px !important;
  text-align: center !important;
  background-color: teal;
}

/** TODO cart - implement Component styling **/

.cart-page {
  height: 100vh;
  margin-top: 20px;

  justify-content: center;
  align-items: center;
}

.Cart-Container {
  width: 900px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid rgb(238, 238, 238);
}

.Header {
  margin: auto;
  width: 90%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: -webkit-right;
}

.Heading {
  font-size: 41px;
  font-weight: 700;
  color: #2f3841;
}

.Action {
  font-size: 14px;

  font-weight: 600;
  color: #e44c4c;
  cursor: pointer;
  border-bottom: 1px solid #e44c4c;
}

.Cart-Items {
  margin: auto;
  width: 90%;
  height: 30%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-box {
  width: 15%;
  min-width: 150px;
  text-align: center;
}

.about {
  height: 100%;
  min-width: 190px;
}

.title {
  line-height: 46px;
  font-size: 32px;

  font-weight: 800;
  color: #202020;
}

.subtitle {
  line-height: 10px;
  font-size: 18px;

  font-weight: 600;
  color: #909090;
}

.counter {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  color: #202020;
  cursor: pointer;
}

.count {
  font-size: 20px;
  font-weight: 900;
  color: #202020;
}

.prices {
  height: 100%;
  text-align: right;
}

.amount {
  padding-top: 20px;
  font-size: 26px;
  min-width: 170px;
  font-weight: 800;
  color: #202020;
}

.save {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #1687d9;
  cursor: pointer;
}

.remove {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #e44c4c;
  cursor: pointer;
}

hr {
  width: 66%;
  float: right;
  margin-right: 5%;
}

.checkout {
  float: right;
  margin-top: 15px;
  margin-right: 5%;
  width: 40%;
}

.total {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Subtotal {
  font-size: 22px;

  font-weight: 700;
  color: #202020;
}

.items {
  font-size: 16px;

  font-weight: 500;
  color: #909090;
  line-height: 10px;
}

.total-amount {
  font-size: 36px;

  font-weight: 900;
  color: #202020;
}

.button {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  border: none;
  background: linear-gradient(to bottom right, #b8d7ff, #8eb7eb);
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #202020;
}

.button.primary {
  float: right;
  width: 80%;
  margin: 0 auto 16px;
  margin-top: 20px;
  display: block;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px;
  text-transform: capitalize;
  color: #fff;
  background: #f03d45;
}


.disabled {
  color: gray !important;
  border-color: gray !important;
  background: #e7e7e7 !important;
}

.charge-payload-container h3 {
  font-weight: bold;
  font-size: 18px;
}

.charge-payload-container {
  margin-top: 40px;
  transition: all .5s ease-in-out;
}

.charge-payload {
  position: relative;
  border: 1px solid #e1dcdc;
  overflow-wrap: break-word;
  margin-top: 10px;
  padding: 10px;
  min-height: 250px;
  margin-bottom: 40px;
  background: #ffffff;
  color: #8d8d8d;
  text-align: left;
}

.charge-payload textarea {
  height: 100%;
  width: 100%;
}

.charge-to-clip {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1000px) {
  .checkout {
    width: 40%;
  }

  .cart-page {
    justify-content: unset;
  }
}

@media only screen and (max-width: 600px) {
  .top-comm-link {
    justify-content: start;
  }

  .content-body {
    margin: 0 5px;
    width: 96%;
  }
}






/*
 PAYMENT CONFIGURATOR SPECIFIC STYLES

 TODO look into module CSS
 */

.modo-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 50%));
  min-height: 100vh;
}

.modo-wrapper {

  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after {
    content: "";
    content: none;
  }

  q:before,
  q:after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    color: #212529;
    line-height: 1.5;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .form-label {
    display: block;
    line-height: 1;
    user-select: none;
    margin-bottom: .5rem;
  }

  .form-input,
  .form-textarea,
  .form-select {
    background-color: #fff;
    border: thin solid #e0e0e0;
    border-radius: 6px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    outline: none;
    outline-style: none;
    padding: 14px;
    position: relative;
    -webkit-appearance: none;
  }

  .form-input.error,
  .form-textarea.error,
  .form-select.error {
    border: 2px solid red;
  }

  .form-input.small,
  .form-textarea.small,
  .form-select.small {
    padding: 8px 10px;
  }

  input[type=color] {
    padding: 8px;
    width: 100px;
    height: 49px;
  }

  .w-100 {
    width: 100%;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 1rem !important;
  }

  .mb-2 {
    margin-bottom: 2rem !important;
  }



  .button-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .modo-left {
    background: #fff no-repeat bottom left;
    padding: 2rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    background: #0f2545;
    padding: 16px;
    color: #fff;
    letter-spacing: 2px;
    border-radius: 6px 6px 0 0;
    text-transform: uppercase;
  }

  .modo-form-content {
    background: #F6F8F7;
    padding: 2rem;
  }

  .modo-form-content.col-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .modo-form-content.col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .modo-form-group {
    margin-bottom: 1.25rem;
  }

  .modo-form-submit {
    margin: 0 auto;
    background: #0f2545;
    box-shadow: 0px 6px 20px rgba(52, 80, 237, 0.4);
    border-radius: 200px;
    padding: 14px 32px;
    border: 0;
    display: block;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    width: 240px;
    outline: 0;
    font-family: "Roboto", sans-serif;
  }

  .modo-right {
    background: #000000 no-repeat top right;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem;
  }

  .modo-right-content {
    width: 80%;
  }

  .modo-right-content .logo {
    margin: 0 auto 1rem;
  }

  .modo-right-content .modo-form-preview {
    background: #F6F8F7;
    padding: 10px;
  }

  .modo-right-content .modo-code {
    background: #434343;
    border-radius: 6px;
    padding: 1.25rem;
    margin-top: 1.25rem;
    color: #fff;
    height: 150px;
    max-height: 225px;
    overflow-y: auto;
    word-break: break-all;
    resize: vertical;
  }

  .switch {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    float: left;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked+.slider {
    background-color: #2196F3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .switch-label {
    display: block;
    letter-spacing: 0px;
    text-transform: capitalize;
    font-weight: 300;
  }

  .switch-label.left {
    margin-right: 5px;
    float: left;
  }

  .switch-label.right {
    margin-left: 5px;
    float: right;
  }
}


.data-entry {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  white-space: pre-wrap;
  max-width: 500px;
  margin: 20px auto;
}

.completed {
  background-color: lightgreen;
}

.authorised {
  background-color: lightblue;
}

.payment_failed {
  margin-top: 33px;
  color: white;
  background-color: lightcoral;
}

.others {
  background-color: lightgray;
}

.not_authorised {
  background-color: #ffffad;
}

.receipt-form {
  margin: 8px 40px;
}

.successDetails {
  margin: 10px 0;
}

/** TODO remove, Tailwind */
.text-left {
  text-align: left;
}

.box-heading {
  font-size: 29px;
  margin: 12px 0;
}

.box .message {
  border-top: 1px solid black;
  margin-top: 11px;
  margin-bottom: 21px;
  padding-top: 21px;
}

.modal-close-btn {
  margin-top: 4px;
  margin-right: 12px;
}

.emailReceiptError {
  padding: 23px 94px;
  text-align: left;
  border-top: 1px solid;
  color: red;
  background: #fff5f5;
}

.top-comm-link {
  display: flex;
  flex-direction: row-reverse;
  gap: 6px;
}

.dmg-pay-widget {
  position: relative;
}

.hpp-content-area {
  h1, h2, h3, h4, h5, h6 {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  a {
    color: var(--secondary-color);
  }

  ol {
    list-style-type: decimal;
    margin-left: 2.5rem;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    opacity: 0.7;
  }
}

.powerered-by-text {
  opacity: 0.3;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    content: 'Loading';
  }

  33% {
    content: 'Loading.';
  }

  67% {
    content: 'Loading..';
  }

  100% {
    content: 'Loading...';
  }
}
